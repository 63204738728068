<template>
  <div>
    <div v-if="loading" class="loader"></div>
    <h3 align="center" v-if="loading" >Loading Data...</h3>
    <div v-if="!loading">
      <div>
        <b-row>
          <b-col lg="12">
            <h3 align="center" v-if="userType !='Admin'">LOGS</h3>
            <h3 align="center" v-if="userType =='Admin'">POINTS LOGS</h3>
            <!-- <p align="center">Error logs are data records that document success,errors,unexpected events within software or systems.</p> -->
            <!-- <p><b><u>Response_Status</u> </b><b class="info"><u>200</u> : </b>indicates success<b class="info"><u>403</u> : </b>indicates Error</p>
            <p><b><u>Action Button</u> : </b> Clicking on Action Button redirect user to another page where the user can save polygon coordinates according to him selecting the OSM Response.</p> -->
            <!-- <input v-if=" userType =='Admin'" class="gap" type="number" v-model="filterText3" min="1" placeholder="User_id..." /> -->
            <input class="gap" type="date" v-model="filterText5" placeholder="Date..." />
            <input class="gap" type="text" v-model="filterText1" placeholder="Locations..." />
            <input class="gap" type="text" v-model="filterText2" placeholder="Country..." />
            <b-form-select class="gap2" type="text" v-model="filterText4" id="statusDropdown" v-if="userType !='Admin'">
              <b-form-select-option value="" disabled>Select Status</b-form-select-option>
              <b-form-select-option value="Alert">Alert</b-form-select-option>
              <b-form-select-option value="Failed">Failed</b-form-select-option>
              <b-form-select-option value="Success">Success</b-form-select-option>
            </b-form-select>
            <b-button class="gap4" variant="primary" @click="handleModalClose">Reset</b-button>
            <!-- <b-button v-if="userType =='Client'" class="gap5" variant="primary" :to="{name:'agenterror'}">Agent Logs</b-button> -->
            <table>
              <thead>
                <tr>
                  <th v-for="(col) in activelogs.columns" :key="col.id">
                    {{ col.toUpperCase() }}
                    <b-icon v-if="col == 'response_status'" id="link-button" icon="exclamation-circle"></b-icon>
                    <b-popover v-if="col == 'response_status'" target="link-button" triggers="hover">
                    <p><b class="info"><u>200</u> : </b>Indicates Success</p>
                    <p><b class="info"><u>403</u> : </b>Indicates Error</p>
                    </b-popover>
                    <b-icon v-if="col == 'action'" id="link-button2" icon="exclamation-circle"></b-icon>
                    <b-popover v-if="col == 'action'" target="link-button2" triggers="hover">
                      <p><b><u>Open Map Button</u> : </b> Clicking on Open Map Button redirect user to another page where the user can save polygon coordinates according to him selecting the OSM Response.</p>
                    </b-popover>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(rw, item) in paginatedData" :key="item">
                  <!-- <td v-if="userType =='Admin'">{{ rw.user_id }}</td>
                  <td v-if="userType =='Admin'">{{ rw.username }}</td> -->
                  <td>{{ rw.date }}<span class="info2"></span>{{ rw.time }}</td>
                  <td v-if="userType =='Admin'">{{ rw.username }}</td>
                  <td>{{ rw.location }}</td>
                  <td>{{ rw.country }}</td>
                  <!-- <td>{{ rw.date }}<span class="info"></span>{{ rw.time }}</td> -->
                  <!-- <td>{{ rw.datetime }}</td> -->
                  <!-- <td v-if="userType !='Admin' && userType !='Client' " >{{ rw.date }}</td> -->
                  <!-- <td>{{ rw.response_status }}</td> -->
                  <td>{{ rw.error }}</td>
                  <td >{{ rw.status }}</td>
                  <td v-if="userType !='Admin'">{{ rw.flag }}</td>
                  <!-- <td v-if="userType !='Admin'">{{ rw.flag }}</td> -->
                  <!-- <td >{{ rw.flag }}</td> -->
                  <td>
                    <!-- <b-button v-if="rw.action =='None'" class="gap3" variant="primary" @click="fetchData(rw, item)">Action</b-button> -->
                    <b-button v-if="rw.action =='None'" class="gap3" variant="primary" :to="{name:'boundary', params: {location:rw.location,country:rw.country,index:'null'}}">Map</b-button>
                    <!-- <b-button v-if="userType =='Admin' && rw.action !='null'"  class="gap3" variant="primary" @click="reportinfo(rw, item)">View</b-button> -->
                    <!-- <b-button v-if="userType =='Admin' && rw.action !='null'"  class="gap3" variant="primary" :to="{name:'report', params: {location:rw.location,country:rw.country,user:rw.user_id,time:rw.time,date:rw.date}}">Logs</b-button> -->
                    <b-button v-if="userType !='Admin' && rw.action !='null'"  class="gap3" variant="primary" :to="{name:'report', params: {location:rw.location,country:rw.country,user:rw.user_id,time:rw.time,date:rw.date}}">Logs</b-button>
                    <p v-if="rw.action =='null'">{{ rw.action }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <b-button variant="primary" @click="previousPage" :disabled="currentPage === 1" class="mx-2 py-1">Previous</b-button>
              <span>{{ currentPage }} / {{ totalPages }}</span>
              <b-button variant="primary" @click="nextPage" :disabled="currentPage === totalPages"  class="mx-2 py-1">Next</b-button>
            </div>
            <!-- <b-table :items="paginatedData" >
              <template #cell(action)="data">
                <b-button v-if="rw.action =='None'" class="gap3" variant="primary" :to="{name:'boundary', params: {location:data.item.location,country:data.item.country}}">Action</b-button>
                    <b-button v-if="userType =='Admin' && data.item.action !='null'"  class="gap3" variant="primary" @click="reportinfo(rw, item)">View</b-button>
                    <b-button v-if="userType =='Admin' && data.item.action !='null'"  class="gap3" variant="primary" :to="{name:'report', params: {location:data.item.location,country:data.item.country,user:data.item.user_id,time:data.item.time,date:data.item.date}}">View</b-button>
                    <p v-if="data.item.action =='null'">{{ data.item.action }}</p>
              </template>
              </b-table> -->
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import axios from '@axios'
import { BRow, BCol, BButton, BFormSelect, BFormSelectOption, BPopover, BIcon}from "bootstrap-vue"
export default {
  components: {
    BRow,
    BCol,BButton,
    BFormSelect, BFormSelectOption, BPopover, BIcon
  },
  data() {
    return {
      loading: true,
      activelogs:null,
      status:null,
      // Reportdata:{
      //   'location':null,
      //   'country':null,
      //   'user':null,
      //   'time':null,
      //   'date':null,
      // },
      // tableData: [], // Your table data array
      filterText1: '',
      filterText2: '',// Filter text
      filterText3:'',
      filterText4:'',
      filterText5:'',
      currentPage: 1,
      itemsPerPage: 25, // Number of items per page
    }
  },
  methods:{
    previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  },
  handleModalClose(){
    this.filterText1=''
    this.filterText2=''
    this.filterText3=''
    this.filterText4=''
    this.filterText5=''
  },
  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  },
      Error: function(){
          axios.post('logs', {
              // headers: { 'Access-Control-Allow-Origin': '*' }
          }).then(response => {
              this.activelogs = response.data.logs
              console.log('this.activelogs',response.data)
          }).catch(error => {
              // console.log("Something went wrong in countryGetList()")
          })
      },
      reportinfo(row, index){
        this.Reportdata={
          location:row.location,
          country: row.country,
          user: row.user_id,
          time:row.time,
          date:row.date,
          
        }
        this.$router.push({ name: 'report', params:{ id:this.Reportdata}});
      },
      Refreshcount: function(){
      axios.post('testrestart', {
          // headers: { 'Access-Control-Allow-Origin': '*' }
      }).then(response => {
          const resetdata=0;
          this.$store.commit('setCounter',resetdata)
          this.status = response.data
      }).catch(error => {
          // console.log("Something went wrong in countryGetList()")
      })
    },
  },
  mounted() {
    // this.Error()
    this.Refreshcount()
    setTimeout(() => {
      this.Error()
      this.loading = false; // Set loading state to false after the data is fetched or processed
    }, 1000); // Simulating a delay (2 seconds) for data fetching, replace this with your actual data fetching logic
  
  },
  computed: {
  filteredData() {
    if (this.filterText3 && this.filterText2 && this.filterText1 && this.filterText4 && this.filterText5) {
      const filterTextlower1 = Number(this.filterText3);
      const filterTextLower2 = this.filterText1.toLowerCase();
      const filterTextLower3 = this.filterText2.toLowerCase();
      const filterTextLower4 = this.filterText4.toLowerCase();
      const filterDatelower5 = this.filterText5;
      return this.activelogs.data.filter(item =>
        (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
        item.location.toLowerCase().includes(filterTextLower2) &&  
        item.country.toLowerCase().includes(filterTextLower3) &&
        item.status.toLowerCase().includes(filterTextLower4) &&
        item.date.includes(filterDatelower5)
      );
    } else if (this.filterText1 && this.filterText2 && this.filterText4 && this.filterText5) {
      const filterTextLower2 = this.filterText1.toLowerCase();
      const filterTextLower3 = this.filterText2.toLowerCase();
      const filterTextLower4 = this.filterText4.toLowerCase();
      const filterDatelower5 = this.filterText5;
      return this.activelogs.data.filter(item =>
        item.location.toLowerCase().includes(filterTextLower2) &&  
        item.country.toLowerCase().includes(filterTextLower3) &&
        item.status.toLowerCase().includes(filterTextLower4) &&
        item.date.includes(filterDatelower5)
      );
    } else if (this.filterText3  && this.filterText2 && this.filterText4 && this.filterText5) {
      const filterTextlower1 = Number(this.filterText3);
      const filterTextLower3 = this.filterText2.toLowerCase();
      const filterTextLower4 = this.filterText4.toLowerCase();
      const filterDatelower5 = this.filterText5;
      return this.activelogs.data.filter(item =>
        (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 && 
        item.country.toLowerCase().includes(filterTextLower3) &&
        item.status.toLowerCase().includes(filterTextLower4) &&
        item.date.includes(filterDatelower5)
      );
    } else if (this.filterText3 && this.filterText1  && this.filterText4 && this.filterText5) {
      const filterTextlower1 = Number(this.filterText3);
      const filterTextLower2 = this.filterText1.toLowerCase();
      const filterTextLower4 = this.filterText4.toLowerCase();
      const filterDatelower5 = this.filterText5;
      return this.activelogs.data.filter(item =>
        (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
        item.location.toLowerCase().includes(filterTextLower2) &&  
        item.status.toLowerCase().includes(filterTextLower4) &&
        item.date.includes(filterDatelower5)
      );
    } else if (this.filterText3 && this.filterText1 && this.filterText2 && this.filterText5) {
      const filterTextlower1 = Number(this.filterText3);
      const filterTextLower2 = this.filterText1.toLowerCase();
      const filterTextLower3 = this.filterText2.toLowerCase();
      const filterDatelower5 = this.filterText5;
      return this.activelogs.data.filter(item =>
        (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
        item.location.toLowerCase().includes(filterTextLower2) &&  
        item.country.toLowerCase().includes(filterTextLower3) &&
        item.date.includes(filterDatelower5)
      );
    } else if (this.filterText3 && this.filterText1 && this.filterText2 && this.filterText4) {
      const filterTextlower1 = Number(this.filterText3);
      const filterTextLower2 = this.filterText1.toLowerCase();
      const filterTextLower3 = this.filterText2.toLowerCase();
      const filterTextLower4 = this.filterText4.toLowerCase();
      return this.activelogs.data.filter(item =>
        (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
        item.location.toLowerCase().includes(filterTextLower2) &&  
        item.country.toLowerCase().includes(filterTextLower3) &&
        item.status.toLowerCase().includes(filterTextLower4)
      );
    } else if ( this.filterText1  && this.filterText4 && this.filterText5) {
      const filterTextLower2 = this.filterText1.toLowerCase();
      const filterTextLower4 = this.filterText4.toLowerCase();
      const filterDatelower5 = this.filterText5;
      return this.activelogs.data.filter(item =>
        item.location.toLowerCase().includes(filterTextLower2) &&
        item.status.toLowerCase().includes(filterTextLower4) &&
        item.date.includes(filterDatelower5)
      );
    } else if ( this.filterText2 && this.filterText4 && this.filterText5) {
      const filterTextLower3 = this.filterText2.toLowerCase();
      const filterTextLower4 = this.filterText4.toLowerCase();
      const filterDatelower5 = this.filterText5;
      return this.activelogs.data.filter(item => 
        item.country.toLowerCase().includes(filterTextLower3) &&
        item.status.toLowerCase().includes(filterTextLower4) &&
        item.date.includes(filterDatelower5)
      );
    } else if ( this.filterText1 && this.filterText2 && this.filterText5) {
      const filterTextLower2 = this.filterText1.toLowerCase();
      const filterTextLower3 = this.filterText2.toLowerCase();
      const filterDatelower5 = this.filterText5;
      return this.activelogs.data.filter(item =>
        item.location.toLowerCase().includes(filterTextLower2) &&  
        item.country.toLowerCase().includes(filterTextLower3) &&
        item.date.includes(filterDatelower5)
      );
    } else if ( this.filterText1 && this.filterText2 && this.filterText4) {
      const filterTextLower2 = this.filterText1.toLowerCase();
      const filterTextLower3 = this.filterText2.toLowerCase();
      const filterTextLower4 = this.filterText4.toLowerCase();
      return this.activelogs.data.filter(item =>
        item.location.toLowerCase().includes(filterTextLower2) &&  
        item.country.toLowerCase().includes(filterTextLower3) &&
        item.status.toLowerCase().includes(filterTextLower4)
      );
    } else if (this.filterText3 &&  this.filterText4 && this.filterText5) {
      const filterTextlower1 = Number(this.filterText3);
      const filterTextLower4 = this.filterText4.toLowerCase();
      const filterDatelower5 = this.filterText5;
      return this.activelogs.data.filter(item =>
        (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
        item.status.toLowerCase().includes(filterTextLower4) &&
        item.date.includes(filterDatelower5)
      );
    } else if (this.filterText3 && this.filterText2 && this.filterText5) {
      const filterTextlower1 = Number(this.filterText3);
      const filterTextLower3 = this.filterText2.toLowerCase();
      const filterDatelower5 = this.filterText5;
      return this.activelogs.data.filter(item =>
        (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
        item.country.toLowerCase().includes(filterTextLower3) &&
        item.date.includes(filterDatelower5)
      );
    } else if (this.filterText3 && this.filterText2 && this.filterText4) {
      const filterTextlower1 = Number(this.filterText3);
      const filterTextLower3 = this.filterText2.toLowerCase();
      const filterTextLower4 = this.filterText4.toLowerCase();
      return this.activelogs.data.filter(item =>
        (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 && 
        item.country.toLowerCase().includes(filterTextLower3) &&
        item.status.toLowerCase().includes(filterTextLower4) 
      );
    } else if (this.filterText3 && this.filterText1  && this.filterText5) {
      const filterTextlower1 = Number(this.filterText3);
      const filterTextLower2 = this.filterText1.toLowerCase();
      const filterDatelower5 = this.filterText5;
      return this.activelogs.data.filter(item =>
        (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
        item.location.toLowerCase().includes(filterTextLower2) &&
        item.date.includes(filterDatelower5)
      );
    } else if (this.filterText3 && this.filterText1 && this.filterText4) {
      const filterTextlower1 = Number(this.filterText3);
      const filterTextLower2 = this.filterText1.toLowerCase();
      const filterTextLower4 = this.filterText4.toLowerCase();
      return this.activelogs.data.filter(item =>
        (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
        item.location.toLowerCase().includes(filterTextLower2) &&
        item.status.toLowerCase().includes(filterTextLower4)
      );
    } else if (this.filterText3 && this.filterText1 && this.filterText2) {
      const filterTextlower1 = Number(this.filterText3);
      const filterTextLower2 = this.filterText1.toLowerCase();
      const filterTextLower3 = this.filterText2.toLowerCase();
      return this.activelogs.data.filter(item =>
        (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
        item.location.toLowerCase().includes(filterTextLower2) &&  
        item.country.toLowerCase().includes(filterTextLower3)
      );
    } else if (this.filterText3 && this.filterText1) {
      const filterTextlower1 = Number(this.filterText3);
      const filterTextLower2 = this.filterText1.toLowerCase();
      return this.activelogs.data.filter(item =>
        (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
        item.location.toLowerCase().includes(filterTextLower2)
      );
    } else if (this.filterText3 && this.filterText2) {
      const filterTextlower1 = Number(this.filterText3);
      const filterTextLower3 = this.filterText2.toLowerCase();
      return this.activelogs.data.filter(item =>
        (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
        item.country.toLowerCase().includes(filterTextLower3)
      );
    } else if (this.filterText3 && this.filterText4) {
      const filterTextlower1 = Number(this.filterText3);
      const filterTextLower4 = this.filterText4.toLowerCase();
      return this.activelogs.data.filter(item =>
        (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
        item.status.toLowerCase().includes(filterTextLower4)
      );
    } else if (this.filterText3 && this.filterText5) {
      const filterTextlower1 = Number(this.filterText3);
      const filterDatelower5 = this.filterText5;
      return this.activelogs.data.filter(item =>
        (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1 &&
        item.date.includes(filterDatelower5)
      );
    } else if (this.filterText1 && this.filterText2) {
      const filterTextLower2 = this.filterText1.toLowerCase();
      const filterTextLower3 = this.filterText2.toLowerCase();
      return this.activelogs.data.filter(item =>
        item.location.toLowerCase().includes(filterTextLower2) &&  
        item.country.toLowerCase().includes(filterTextLower3)
      );
    } else if (this.filterText1 && this.filterText4) {
      const filterTextLower2 = this.filterText1.toLowerCase();
      const filterTextLower4 = this.filterText4.toLowerCase();
      return this.activelogs.data.filter(item =>
        item.location.toLowerCase().includes(filterTextLower2) &&  
        item.status.toLowerCase().includes(filterTextLower4)
      );
    } else if (this.filterText1 && this.filterText5) {
      const filterTextLower2 = this.filterText1.toLowerCase();
      const filterDatelower5 = this.filterText5;
      return this.activelogs.data.filter(item =>
        item.location.toLowerCase().includes(filterTextLower2) &&
        item.date.includes(filterDatelower5)
      );
    } else if (this.filterText2 && this.filterText4) {
      const filterTextLower3 = this.filterText2.toLowerCase();
      const filterTextLower4 = this.filterText4.toLowerCase();
      return this.activelogs.data.filter(item =>
        item.country.toLowerCase().includes(filterTextLower3) &&
        item.status.toLowerCase().includes(filterTextLower4)
      );
    } else if (this.filterText2 && this.filterText5) {
      const filterTextLower3 = this.filterText2.toLowerCase();
      const filterDatelower5 = this.filterText5;
      return this.activelogs.data.filter(item =>
        item.country.toLowerCase().includes(filterTextLower3) &&
        item.date.includes(filterDatelower5)
      );
    } else if (this.filterText4 && this.filterText5) {
      const filterTextLower4 = this.filterText4.toLowerCase();
      const filterDatelower5 = this.filterText5;
      return this.activelogs.data.filter(item =>
        item.status.toLowerCase().includes(filterTextLower4) &&
        item.date.includes(filterDatelower5)
      );
    } else if (this.filterText3) {
      const filterTextlower1 = Number(this.filterText3);
      return this.activelogs.data.filter(item =>
        (typeof item.user_id === 'number' ? item.user_id : Number(item.user_id)) === filterTextlower1
      );
    } else if (this.filterText1) {
      const filterTextLower2 = this.filterText1.toLowerCase();
      return this.activelogs.data.filter(item =>
        item.location.toLowerCase().includes(filterTextLower2)
      );
    } else if (this.filterText2) {
      const filterTextLower3 = this.filterText2.toLowerCase();
      return this.activelogs.data.filter(item =>
        item.country.toLowerCase().includes(filterTextLower3)
      );
    } else if (this.filterText4) {
      const filterTextLower4 = this.filterText4.toLowerCase();
      return this.activelogs.data.filter(item =>
        item.status.toLowerCase().includes(filterTextLower4)
      );
    } else if (this.filterText5) {
      const filterDatelower5 = this.filterText5;
      return this.activelogs.data.filter(item =>
        item.date.includes(filterDatelower5)
      );
    } else {
      console.log("this is else condition")
      return this.activelogs.data;
    }
  },
  totalPages() {
    return Math.ceil(this.filteredData.length / this.itemsPerPage);
  },
  paginatedData() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.filteredData.slice(startIndex, endIndex);
  },
  getColumnClass(index) {
    // Add a specific class to the second column
    return index === 3 ? "modified-column" : "";
  },
  getColumnContent(col, index) {
    // Modify the content of the second column
    if (index === 3) {
      return `<i class="fas fa-user"></i> ${col}`;
    } else {
      return col;
    }
  },
},
}
</script>
<style>
.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
  margin-top: 200px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.gap{
margin-right: 5px;
border-color:black;
width: 250px;
}
.gap2{
margin-top: 10px;
border-color:black;
width:15%;
background:white;
}
.gap3{
margin-left: 5px;
margin-top: 5px;
}
.gap4{
margin-left:20px;
width: 100px;
height: 48px;
}
.gap5{
margin-left:20px;
width: 120px;
height: 48px;
}
.info{
  padding-left:20px;
}
.info2{
  padding-left:5px;
}
</style>
<script setup>
const userType = localStorage.getItem('userType');
</script>
