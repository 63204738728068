<template>
    <div>
      <!-- <input class="gap_admin" type="number" v-model="filterText1" min="1" placeholder="User_id..." />
      <input class="gap_admin" type="text" v-model="filterText2" placeholder="Locations..." /> -->
        <table>
            <thead>
                <tr>
                <th>UserId</th>
                <th>User</th>
                <th>Status</th>
                <!-- <th>Plan</th> -->
                <th>Total Request</th>
                <!-- <th>Available Request</th> -->
                <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td class="column_width">{{ item.User_id}}</td>
                    <td class="td basic_cursor width">
                        <router-link class="link" :to="{name:'clientlogs', params: {user: item.Name,id:item.Encrypted_id}}">{{ item.User }}</router-link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>({{ item.date }})&nbsp;&nbsp;{{ item.ago }}</b>
                    </td>
                    <!-- <td class="td basic_cursor">
                        <router-link class="link" :to="{name:'userDetails', params: {user: item.User}}">{{ item.User }}</router-link>
                    </td> -->
                    <td class="column_width">{{ item.Status }}</td>
                    <!-- <td class="column_width">{{ item.Plan }}</td> -->
                    <td class="column_width">{{ item.Total_request }}</td>
                    <!-- <td>{{ item.Available_request}}</td> -->
                    <td>
                        <button @click="userreport(item.User,item.Name)" class="me-1 btn btn-sm btn-primary">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Token&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
                        <button @click="handleButtonSupplierList(item.User)" class="me-1 btn btn-sm btn-primary">Assign supplier</button>
                        <!-- <button @click="clientcitylist(item.Name,item.Encrypted_id)" class="me-1 btn btn-sm btn-primary">&nbsp;&nbsp;&nbsp;City List&nbsp;&nbsp;&nbsp;</button> -->
                        <button v-if="item.Status == 'Active'" @click="handleButtonClick3(item.User)" class="me-1 btn-sm btn btn-danger">Deactivate</button>
                        <button v-else @click="handleButtonClick2(item.User)" class="me-1 btn btn-sm btn-success">&nbsp;&nbsp;&nbsp;Activate&nbsp;&nbsp;&nbsp;</button>
                    </td>
                </tr>
            </tbody>
        </table>

        <b-modal v-model="modalstatus" ok-title="Save" cancel-title="Cancel" id="modal_chkbxgrp" title="Suppliers List" @ok="handleOk" @cancel="handleCancel" @close="handleModalClose">
            <b-form-group class="heiscrl">
            <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="checked1"
                :options="checked1options"
                :aria-describedby="ariaDescribedby"
                name="flavour-1"
            ></b-form-checkbox-group>
            <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="unselectedCheckboxes"
                :options="unchecked"
                :aria-describedby="ariaDescribedby"
                name="flavour-1"
            ></b-form-checkbox-group>
            </b-form-group>
        </b-modal>


    </div>
    
</template>
<script>
import axios from '@axios'
// import { BIconArrowUp, BIconArrowDown } from 'bootstrap-vue'

export default {
    data() {
        return{
            showDropdown: false,
            names:[],
            supplier_list:[],
            selectedSuppliers:  [],
            selectedName: [],
            modalstatus:false,
            modalstatus1:false,
            checked1:[],
            unchecked:[],
            selectedCheckboxes:true,
            unselectedCheckboxes:[],
            checked1options:[],
            tableData:[],
            selectedUser: null,
            email:null,
            token:null,
            userview:null,
            daily_request:null,
            joining_date:null,
            last_hit:null,
            filterText1: '',
            filterText2: '',
        }
    },
    // components: {
    // BIconArrowUp,
    // BIconArrowDown
    // },
    mounted(){
        // axios.post('admin')
        //     .then(response => {
        //         console.log(response.data)
        //         // this.names = response.data.name;
        //         this.tableData = response.data;
        //         console.log(this.tableData);

        //     })
        //     .catch(error => {
        //         console.error('Error fetching data:', error);
        //     });
        this.clientlist()
    },
      
    methods: {
        toggleDropdown() {
        this.showDropdown = !this.showDropdown;
        },
        clientlist: function(){
            axios.post('admin')
            .then(response => {
                console.log(response.data)
                // this.names = response.data.name;
                this.tableData = response.data;
                console.log(this.tableData);

            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
        },
        userreport(User,Name){
            this.$router.push({name:'userDetails', params: {user:User,name:Name}});
        },
        clientcitylist(User,Id){
            this.$router.push({name:'clientcitylist', params: {user:User,id:Id}});
        },
        handleButtonSupplierList(user){
            this.selectedUser = user;
            console.log("Button 1 clicked for user:", user);
            // supplier_list
            // this.modalstatus = true;
            try{
                const response = axios.post('supplierlist', {
                    username:user
                }, { headers: {'Access-Control-Allow-Origin': '*'}}).then(response => {
                    this.checked1=response.data.checked;
                    this.unchecked=response.data.unchecked,
                    // console.log(this.unchecked)
                    this.modalstatus=true;
                    response.data.checked.forEach(element => {
                        this.checked1options.push({ text: element, value: element });
                    })
                    console.log(this.checked1options)
                    
                });
                // console.log(response.data);
                } catch (error) {
                console.error(error);
            }
        },
        handleButtonClick2(user){
            try{
                const response = axios.post('activateuser', {
                    username:user
                }, { headers: {'Access-Control-Allow-Origin': '*'}}).then(response => { 
                    this.clientlist()  
                });
                } 
                catch (error) {
                console.error(error);
            }
        },
        handleButtonClick3(user){
            try{
                const response = axios.post('deactivateuser', {
                    username:user
                }, { headers: {'Access-Control-Allow-Origin': '*'}}).then(response => {
                    this.clientlist()
                });
                } 
                catch (error) {
                console.error(error);
            }

        },
        // async handleButtonClick4(user){
        //     console.log(user)
        //     try{
        //         const userRequests = [
        //             axios.post('userinfo', { username: user }),
        //             axios.post('userinfo1', { username: user })
        //             // Add more POST requests as needed
        //         ];
        //         const userResponses = await Promise.all(userRequests);
        //         const userData = {
        //             email: userResponses[1].data.Email,
        //             token: userResponses[1].data.Token,
        //             userview: userResponses[0].data.User,
        //             daily_request: userResponses[0].data.daily_request,
        //             joining_date: userResponses[1].data.joining_date,
        //             last_hit: userResponses[1].data.last_hit
        //         }
        //         delete userData.disabledParameter1;
        //         this.$router.push({
        //         name: 'userDetails',
        //         params: userData
        //         });
        //         // console.log(response.data);
        //         } catch (error) {
        //         console.error(error);
        //     }
            
            
        // },
        handleOk(){
            try{
                const response = axios.post('usersupplier', {
                    chk1 : this.checked1,
                    chk2:this.unselectedCheckboxes,
                    username:this.selectedUser

                }, { headers: {'Access-Control-Allow-Origin': '*'}}).then(response => {
                    console.log(response);
                    alert('Suppliers saved Sucesfully');
                    window.location.reload();
                });
                console.log(response.data);
                } catch (error) {
                console.error(error);
            }
        },
        Supplierselect(){
            console.log('asdf')
            console.log(this.selectedName)
            try{
                const response = axios.post('supplierlist', {
                    username:this.selectedName
                }, { headers: {'Access-Control-Allow-Origin': '*'}}).then(response => {
                    this.checked1=response.data.checked;
                    this.unchecked=response.data.unchecked,
                    // console.log(this.unchecked)
                    this.modalstatus=true;
                    response.data.checked.forEach(element => {
                        this.checked1options.push({ text: element, value: element });
                    })
                    console.log(this.checked1options)
                    
                });
                // console.log(response.data);
                } catch (error) {
                console.error(error);
            }
        },
        handleCancel(){
            window.location.reload();
        },
        handleModalClose(){
            window.location.reload();
        },
    },
//     computed: {
//     filteredData() {
//       if (this.filterText1) {
//         const filterTextlower1 = Number(this.filterText1);
//         return this.tableData.filter( item =>
//           (typeof item.User_id === 'number' ? item.User_id : Number(item.User_id)) === filterTextlower1 
//         );
//       } else if (this.filterText2) {
//         const filterTextLower2 = this.filterText2.toLowerCase();
//         return this.tableData.filter( item =>
//           item.Plan.toLowerCase().includes(filterTextLower2)
//         );
//       }else {
//         return this.tableData[0].User_id;
//       }
//     },
//   },
}


</script>
<style>
.column_width{
    width: 150px;
}
.link{
  color:blue;
  font-weight: bold;
}
.gap_admin{
  margin-right: 5px;
  margin-left: 20px;
  border-color:black;
  width: 250px;
}
.td:hover{
    border-bottom: thin solid #ddd;
    background-color: #6bafa0;
    color: #fff !important;
}
.width{
    width:500px !important;
}
.basic_cursor{
    cursor: pointer;
}
</style>
