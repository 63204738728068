<template>
  <div class="sidebar__left" id="toggleMenu">
      <div class="position-relative">
        <div class="logo__company"><img src="/static/img/bizlogo.png" alt="bizlogo" @click="redirectToPage"></div>
        <!-- <div class="logo__company"><router-link to="/dashboard"><img src="/static/img/bizlogo.png" alt="bizlogo"></router-link></div> -->
        <div class="menu__items">
          <router-link to="/dashboard">
            <div class="menu__item__single active__menu__item"><b-icon icon="speedometer2"></b-icon>
              <p>Dashboard</p>
            </div>
          </router-link>
          <!-- <router-link to="/api" v-if="userType == 'Admin'">
            <div class="menu__item__single"><b-icon icon="cloud-arrow-up"></b-icon>
              <p>API</p>
            </div>
          </router-link> -->
          <router-link to="/destination">
            <div class="menu__item__single"><b-icon icon="geo"></b-icon>
              <p>Locations</p>
            </div>
          </router-link>
          <router-link to="/map">
            <div class="menu__item__single"><b-icon icon="map"></b-icon>
              <p>Map</p>
            </div>
          </router-link>
          <router-link to="/savepolygonresponse" v-if="userType == 'Admin'">
            <div class="menu__item__single"><b-icon icon="save2"></b-icon>
              <p>Save Polygon Response</p>
            </div>
          </router-link>
          <router-link to="/supplierinfo">
            <div class="menu__item__single"><b-icon icon="file-person" id="link-button7"></b-icon>
              <b-popover target="link-button7" triggers="hover">
                <p>API To Fetch Json Response of HotelCode With Associated Suppliers</p>
              </b-popover>
              <p>Search By HotelID</p>
            </div>
          </router-link>
          <router-link to="/hotelnameinfo">
            <div class="menu__item__single"><b-icon icon="building" id="link-button6"></b-icon>
              <b-popover target="link-button6" triggers="hover">
                <p>API To Fetch Json Response of HotelCode With Associated Suppliers</p>
              </b-popover>
              <p>Search By HotelName</p>
            </div>
          </router-link>
          <router-link to="/hoteldetails">
            <div class="menu__item__single"><b-icon icon="info-square" id="link-button4"></b-icon>
              <b-popover target="link-button4" triggers="hover">
                <p>API To Fetch Json Response of Hoteldetails Using HotelCode/SupplierCode</p>
              </b-popover>
              <p>HotelDetails (HotelCode)</p>
            </div>
          </router-link>
          <router-link to="/hotelinformation">
            <div class="menu__item__single"><b-icon icon="info-square" id="link-button5"></b-icon>
              <b-popover target="link-button5" triggers="hover">
                <p>API To Fetch Json Response of Hoteldetails Using OTID/OTCode</p>
              </b-popover>
              <p>HotelDetails (OTID/Code)</p>
            </div>
          </router-link>
          <router-link to="/error">
            <div class="menu__item__single position-relative">
              <b-icon v-if="userType == 'Admin'" icon="journal-album" id="link-button3"></b-icon>
              <b-icon v-if="userType != 'Admin'" icon="bug" id="link-button3"></b-icon>
              <!-- <span class="counterFloat" v-if="counterValue!=0">{{ counterValue  }}</span> -->
              <b-badge class="counterFloat" variant="primary" v-if="counter!=0">{{ counter}}</b-badge>
              <b-popover target="link-button3" triggers="hover" v-if="userType != 'Admin'" >
                <p>Error logs are data records that document success,errors,unexpected events within software or systems.</p>
              </b-popover>
              <p>Show Logs</p>
            </div>
          </router-link >
          <router-link to="/setting" v-if="userType == 'Admin'">
            <div class="menu__item__single"><b-icon icon="gear"></b-icon>
              <p>Settings</p>
            </div>
          </router-link>
          <!-- <router-link to="/agentsettings" v-if="userType == 'Client'">
            <div class="menu__item__single"><b-icon icon="person-fill"></b-icon>
              <p>Agent Settings</p>
            </div>
          </router-link> -->
          <!-- <router-link to="/xmlout" v-if="userType == 'Admin'">
            <div class="menu__item__single"><b-icon icon="book"></b-icon>
              <p>Xmlout Logs</p>
            </div>
          </router-link> -->
        </div>
        <div class="menu__bottom__items">
          <div class="menu__bottom_item__single"><b-icon icon="question-lg"></b-icon>
            <p>FAQ</p>
          </div>
          <!-- <router-link to="/log">
          <div class="menu__bottom_item__single"><b-icon icon="box-arrow-right"></b-icon>
            <p>Sign off</p>
          </div>
        </router-link> -->
        </div>
      </div>
    </div>
</template>
<script>
// import axios from '@axios'
import { 
BPopover,BIcon, BBadge
} from 'bootstrap-vue'
export default {
  data() {
      return {

      }
  },
  components: {
    BPopover,BIcon, BBadge
  },
  methods:{
    redirectToPage(){
      this.$router.push('/dashboard');
    }
  }
}
</script>

<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import store from '../../store';
import axios from '@axios';

const userType = localStorage.getItem('userType');
const counter = computed(() => store.getters.getCounter)
const error = ref(null);
let intervalId;

const fetchCounterValue = async () => {
  try {
    const response = await axios.post("test");  // Replace with your actual API endpoint
    store.commit('setCounter', response.data.count)
    console.log(counter.value)
    error.value = null; // Reset error if the request is successful
  } catch (error) {
    console.error('Error fetching counter value:', error);
    error.value = 'Failed to fetch counter value';
  }
};

onMounted(() => {
  intervalId = setInterval(() => {
    fetchCounterValue();
  }, 10000);
});

onUnmounted(() => {
  clearInterval(intervalId);
});

// console.log(counter)
</script>

<style>
.counterFloat{
  background: #00a8e9 !important;
  border-radius: 50px !important;
  font-size: 8px !important;
  padding: 4px 4px !important;
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
  position: absolute !important;
  top: -10px !important;
  right: 3px !important;
}
.menuActive{
  background: #378ad6;
  border-radius: 8px;
}
.menuActive p{
  display: none !important;
}
.menuActive p:hover{
  display: none !important;
}
.menu__items a{
  margin-bottom: 0px !important;
    padding: 14px;
  }
  
  .menuActive .b-icon.bi{
    color: #fff !important;
}
.sidebar__left .menu__items .menu__item__single {
    height: 20px !important;
    margin-bottom: 0 !important;
    color: #a1a1a1 !important;
    text-align: center !important;
    font-size: 20px !important;
    cursor: pointer !important;
    transition: 0.3s all !important;
}
.sidebar__left .menu__items {
    display: flex;
    margin-top: 15px !important;
}
.sidebar__left .menu__items .menu__item__single p {
    font-size: 8px !important;
    font-weight: 500 !important;
    margin-top: 10px !important;
    color: transparent !important;
    transition: 0.3s all !important;
}
</style>

