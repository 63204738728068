<template>
  <div>
    <section class="log__page">
        <img src="/static/img/bac3.jpg" alt="background image">
        <img src="https://www.bizinso.com/wp-content/uploads/2022/07/Logo-for-website-100px-width.svg" class="logo__img" alt="logo image">
        <div class="login__form">
            <div class="text-center">
                <h3>Account Login</h3>
                <p>Hey, Enter your details to get sign in <br>to your account</p>
            </div>
            <form action="signin/" method="POST">
                <div class="input-field">
                    <input type="text" v-model="userData.username" required spellcheck="false"> 
                    <label>Enter username</label>
                </div>
                <div class="input-field mb-5">
                    <input type="password" v-model="userData.password" name="password" @keyup.enter="login()" required spellcheck="false"> 
                    <label>Enter password</label>
                </div>
                
                <!-- <a href="" class="forgot__pass">Forgot Password?</a> -->
                <button type="button" @click="login()">Sign in</button>
                <!-- <p class="signup__line">Don't have an account? <router-link to="/register"><b>Request now</b></router-link></p> -->
            </form>
        </div>
    </section>
  </div>
</template>
<script>
import axios from '@axios'

export default {
  name: 'Login',
  components: {
    },
    data() {
        return {
            userData: {
                username:null,
                password:null,
            }
        }
    },
    methods: {
        login() {
            axios.post('signin/', this.userData).then(response=>{
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('userData', response.data.userdata.username)
                localStorage.setItem('userType', response.data.user)
                localStorage.setItem('image_url', response.data.imageurl)
                this.$router.push({name : 'dashboard'})
            }).catch(error=>{
                alert("Please check your credential")
            })
        }
    }
}
</script>